import { Link } from 'react-router-dom';
import menu from './data/menu';
import React, { useState, useEffect } from 'react'
import DesktopMenu from "./components/DesktopMenu";
import MenuToggle from "./components/MenuToggle";
import MobileMenu from "./components/MobileMenu";
import ContactForm from "./components/ContactForm";
import ProjectForm from './components/ProjectForm';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function scrollToId(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

function Layout({ children }) {
  const [menuVisible, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!menuVisible)
  const hideMenu = () => setMenu(false)
  const [isDiscussForm, setShowDiscussForm] = useState(false);
  const [isProjectForm, setShowProjectForm] = useState(false);

  const [activeSection, setActiveSection] = useState('');

  const isSectionActive = (sectionElement, scrollPosition, menuHeight) => {
    if (sectionElement) {
      const sectionTop = sectionElement.offsetTop;
      const sectionHeight = sectionElement.clientHeight;
      return scrollPosition >= sectionTop - menuHeight && scrollPosition < sectionTop + sectionHeight;
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
  
      const menuElement = document.getElementById("desktopMenu");
      const menuHeight = menuElement ? menuElement.clientHeight : 0;
  
      const activeSectionElement = document.getElementById(activeSection);
      if (!isSectionActive(activeSectionElement, scrollPosition, menuHeight)) {
        setActiveSection('');
      }
  
      menu.forEach(({route}) => {
        const sectionElement = document.getElementById(route);
        if (isSectionActive(sectionElement, scrollPosition, menuHeight)) {
          setActiveSection(route);
        }
      });
    };  
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);
  

  const hideDiscussForm = () => {
    setShowDiscussForm(false)
  }
  const showDiscussForm = () => {
    setShowDiscussForm(true)
  }

  const hideProjectForm = () => {
    setShowProjectForm(false)
  }
  const showProjectForm = () => {
    setShowProjectForm(true)
  }

  return (
    <div className="flex w-full h-full flex-col bg-sblack">

      {
        isDiscussForm ? <div className="fixed overflow-y-auto flex-grow content-center w-full top-0 h-full z-50 bg-swhite">
          <span className="absolute right-0 top-0 font-header-h2 mr-6 cursor-pointer" onClick={hideDiscussForm}>&times;</span>
          <div className='pt-10'>
            <h2 className="font-header-h2 text-center mb-8 mt-2" id="contact">Форма обратной связи</h2>
            <ContactForm successCallback={hideDiscussForm} />
          </div>
        </div>
          : ''
      }

      {
        isProjectForm ? <div className="fixed overflow-y-auto flex-grow content-center w-full top-0 h-full z-50 bg-swhite">
          <span className="absolute right-0 top-0 font-header-h2 mr-6 cursor-pointer" onClick={hideProjectForm}>&times;</span>
          <div className='pt-10'>
            <h2 className="font-header-h2 text-center mb-8 mt-2" id="contact">Хотите обсудить проект?</h2>
            <ProjectForm successCallback={hideProjectForm} />
          </div>
        </div>
          : ''
      }

      <nav className="w-full fixed z-20">
        <Link onClick={() => window.scrollTo(0, 0)}>
          <div className="logo" />
        </Link>

        <div className="flex justify-end items-center bg-sblack
          lg:justify-center
          h-[55px]
          md:h-[75px]
          ">
          <DesktopMenu activeSection={activeSection} onClick={scrollToId} menu={menu} />

          <div className="absolute
           right-[5rem] rounded-[2rem] border-[1px] border-1 border-saccent h-[40px] w-[170px] text-center
           p-[12px] text-xs text-white transition-all
           opacity-0
           lg:opacity-100
           hover:bg-saccent cursor-pointer"
            onClick={showDiscussForm}
          >
            НАПИСАТЬ НАМ
          </div>

          <MenuToggle toggleMenu={toggleMenu} menuVisible={menuVisible} />
        </div>
        <MobileMenu activeSection={activeSection} onClick={scrollToId} menu={menu} menuVisible={menuVisible} hideMenu={hideMenu} showDiscussForm={showDiscussForm} />
      </nav>

      <div className="h-[55px]"></div>

      <main className="w-full">
        {React.Children.map(children, child => {
          return React.cloneElement(child, { showProjectForm });
        })}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          style={{ bottom: '100px' }}
          hideProgressBar={true}
        />
      </main>

      <footer className="w-full
              left-0 bottom-0
              flex flex-col justify-center items-center
              mt-auto
              bg-sblack
              text-white
              text-xs
              ">
        <div className='hidden lg:block w-full'>
          <div className='w-full p-5 pb-0 flex justify-between flex-row'>
            <a className='text-regular underline' target="_blank" href='/doc/summary_of_results.pdf' >Сводные данные о результатах проведения специальной оценки условий труда</a>
            <div>+7 (498) 719-0-917</div>
          </div>

          <div className='w-full p-5 pt-2 flex justify-between flex-row'>
            <a className='text-regular underline' target="_blank" href='/doc/privat_policy.pdf' >Политика конфиденциальности</a>
            <a href="mailto:info@sprinterra.ru" class="text-regular underline">info@sprinterra.ru</a>
          </div>

          <div className="w-full p-5 flex align-end items-end flex-col">
            <div> © ООО «Спринтерра», {new Date().getFullYear()}. Все права защищены.</div>
          </div>
        </div>

        <div className='lg:hidden w-full flex flex-col py-6 px-1 text-center justify-center'>
          <a className='text-regular underline' target="_blank" href='/doc/summary_of_results.pdf' >Сводные данные о результатах проведения специальной оценки условий труда</a>
          <a className='text-regular pt-2 underline' target="_blank" href='/doc/privat_policy.pdf' >Политика конфиденциальности</a>
          
          <div className='py-6'>
            <div>+7 (498) 719-0-917</div>
            <a href="mailto:info@sprinterra.ru" class="text-regular underline">info@sprinterra.ru</a>
          </div>
          
          <div> © ООО «Спринтерра», {new Date().getFullYear()}. Все права защищены.</div>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
