import Expertise from "./Expertise";
import LightBlock from "../components/LightBlock";
import Separator from "../components/Separator";
import DarkBlock from "../components/DarkBlock";
import Technologies from "../components/Technologies";
import WorkProcesses from "../components/WorkProcesses";
import Projects from "../components/Projects";
import ProjectForm from "../components/ProjectForm";

function About({ showProjectForm }) {
  return (
    <div id="services">
      <Separator className="rounded-t-3xl bg-sgray" />
      <LightBlock className="pt-8 md:pt-[6.5rem]">
        <div className="text-center">
          <h2>Наша экспертиза</h2>
        </div>
        <div class="flex justify-center items-center">
          <div className="max-w-[1296px] grid grid-container grid-cols-1 pt-12 md:pt-0 md:grid-cols-2 gap-8 md:mt-[80px]">
            <div className="grid-item">
              <div className="max-w-[500px] font-text-regular">
                Высокая инженерная культура, гибкая методология разработки и использование современных технологий
                позволяют
                нам создавать новые решения и легко вливаться в команду заказчика, обогащая ее своими компетенциями.
              </div>
            </div>
            <div className="grid-item">
              <Expertise />
            </div>
          </div>
        </div>
      </LightBlock>
      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-sblack" />
      </div>
      <DarkBlock className="pt-8 md:pt-[6.5rem]">
        <h2 className="w-full text-center pb-[3.125rem]">Технологии</h2>
        <div className="lg:flex lg:justify-center lg:items-center">
          <div className="max-w-[1096px]">
            <Technologies />
          </div>
        </div>
      </DarkBlock>
      <Separator className="rounded-t-3xl bg-sgray" />
      <LightBlock className="pt-8 md:pt-[6.5rem]">
        <h2 className="w-full text-center pb-[3.125rem] md:pb-20">Рабочий процесс</h2>
        <WorkProcesses />
      </LightBlock>
      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-saccent" />
      </div>
      <LightBlock className="bg-saccent pt-8 md:pt-[6.5rem]">
        <div className="projects-grid">
          <h2 className="w-full text-center  pb-[3.125rem] md:pb-20">Наши проекты</h2>
          <div className="grid-item justify-end
          projects-container">
            <div class="flex justify-center items-center">
              <div className="max-w-[1296px]">
                <Projects />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center">
          <div className="md:hidden mt-20 right-[5rem] rounded-[2rem] border-[1px] border-1 border-saccent h-[40px] w-[170px] text-center
              p-[12px] text-xs text-white bg-sblack border-sblack border-[1px]
              hover:bg-saccent cursor-pointer"
            onClick={showProjectForm}
          >
            ОБСУДИТЬ ПРОЕКТ
          </div>
        </div>

        <Separator className="hidden md:block h-[150px] bg-saccent" />
        <div className="hidden md:flex justify-center">
          <div className="bg-swhite w-full md:w-10/12 max-w-[1296px] rounded-[2rem] p-[1rem]">
            <div className="flex flex-col align-middle justify-center items-center">
              <h2 className="font-header-h2 text-center mb-8 mt-2" id="contact">Хотите обсудить проект?</h2>
              <ProjectForm />
            </div>
          </div>
        </div>
      </LightBlock>
      <Separator className="hidden md:flex bg-sblack h-[400px] mt-[-400px]" />
      <div className="bg-saccent md:bg-sblack">
        <Separator className="rounded-t-3xl bg-sgray" />
      </div>

    </div>
  );
}

export default About;
