import LightBlock from "../components/LightBlock";
import DarkBlock from "../components/DarkBlock";
import Separator from "../components/Separator";
import {useLayoutEffect, useRef, useState} from "react";

const MAX_ITEMS = 3
const INTERVAL = 500
const TIMEOUT = 0
const LeaderSlider = () => {
  let item = useRef(0);
  const [currentItem, setCurrentItem] = useState(0);
  let intervalLg = useRef(0);
  let timeoutLg = useRef(0);

  useLayoutEffect(() => {
    const setItem = (id) => {
      setCurrentItem(id);
      const currentActive = document.querySelector('#car-sm img.active');
      const nextActive = document.querySelectorAll('#car-sm img')[id];
      currentActive.classList.remove('active')
      nextActive.classList.add('active')
    }
    const nextItem = () => {
      if (item.current === 0) {
        item.current = 1
      } else {
        item.current = 0
      }
      setItem(item.current)
    };

    const carSm = document.getElementById('car-sm');

    carSm.onclick = function (e) {
      nextItem()
    }
    }, [])


  useLayoutEffect(() => {
    const setItem = (id) => {
      setCurrentItem(id);
      const currentActive = document.querySelector('#car-lg img.active');
      const nextActive = document.querySelectorAll('#car-lg img')[id];

      currentActive.classList.remove('active')
      nextActive.classList.add('active')
    }

    const nextItem = () => {
      if (item.current < MAX_ITEMS - 1) {
        item.current += 1
      } else {
        item.current = MAX_ITEMS - 1
      }

      setItem(item.current)
    };

    const carLg = document.getElementById('car-lg');

    carLg.onclick = function (e) {
      nextItem()
      clearInterval(intervalLg.current)
      intervalLg.current = setInterval(nextItem, INTERVAL)
    }

    carLg.onmouseover = function (e) {
      if(item.current === 0) {
        nextItem()
        timeoutLg.current = setTimeout(() => {
          intervalLg.current = setInterval(nextItem, INTERVAL)
        }, TIMEOUT)
      }
    }

    carLg.onmouseout = function (e) {
      item.current = 0
      setItem(item.current)
      console.log('onmouseout' );
      clearInterval(intervalLg.current)
      clearTimeout(timeoutLg.current)
    }
  }, [])

  return (
    <div className="carousel">

      <div className="block md:hidden min-w-[300px]">
        <div
          id="car-sm"
        >
            <img
              alt={`manager 1 `}
              key={`manager-1-sm`}
              src={`/img/manager/manager1.png`}
              className={'active'}
            />
            <img
              alt={`manager 3`}
              key={`manager-3-sm`}
              src={`/img/manager/manager3.png`}
            />
        </div>
        <div className="w-full pt-4 flex flex-row justify-center">
          <div
            className={`w-3 h-3 mx-1 rounded-full ${currentItem === 0 ? 'bg-white' : 'bg-swhite bg-opacity-30'}`}
          />
          <div
            className={`w-3 h-3 mx-1 rounded-full ${currentItem === 1 ? 'bg-white' : 'bg-swhite bg-opacity-30'}`}
          />
        </div>
      </div>

      <div className="hidden md:block min-w-[400px]">
        <div
          id="car-lg"
        >
          <img
            alt={`manager 1 `}
            key={`manager-1-sm`}
            src={`/img/manager/manager1.png`}
            className={'active'}
          />
          <img
            alt={`manager 2`}
            key={`manager-2-sm`}
            src={`/img/manager/manager2.png`}
          />
          <img
            alt={`manager 3`}
            key={`manager-3-sm`}
            src={`/img/manager/manager3.png`}
          />
        </div>
        <div className="w-full mt-4 flex flex-row justify-center">
          <div
            className={`w-3 h-3 mx-1 rounded-full ${currentItem === 0 ? 'bg-white' : 'bg-swhite bg-opacity-30'}`}
          />
          <div
            className={`w-3 h-3 mx-1 rounded-full ${currentItem === 1 ? 'bg-white' : 'bg-swhite bg-opacity-30'}`}
          />
          <div
            className={`w-3 h-3 mx-1 rounded-full ${currentItem === 2 ? 'bg-white' : 'bg-swhite bg-opacity-30'}`}
          />
        </div>
      </div>
    </div>
  );
};

const Result = ({header, text}) => {
  return (
    <div className="pt-[80px] pb-[80px] min-w-[300px] rounded-[20px] border border-saccent border-opacity-30 flex flex-col
     align-center justify-center items-center text-center">
      <div className="font-header-h3">{header}</div>
      <div className="w-[50px] mt-3 border-[1px] border-sblack"></div>
      <div className="mt-5 font-text-regular">{text}</div>
    </div>
  )
}

function AboutCompany() {
  return (
    <div id="about">
      <LightBlock className="pt-8 md:pt-[6.5rem]">
        <div className="text-center">
          <h2 className="w-full text-center pb-[3.125rem] md:pb-20">О Компании</h2>
        </div>

        <div className="flex flex-col xl:flex-row items-start justify-center md:gap-16">
          <div className="flex flex-col self-center">
            <div className="xl:max-w-[576px] display-inline">
              ООО «Спринтерра» – аккредитованная ИТ-компания,
              специализирующаяся на разработке цифровых платформ
              и интегрированных комплексных решений в сфере образования, систем публикации и управления контентом.
            </div>
            <img src="/img/about_res/1.jfif" className="self-center max-w-[336px] lg:max-w-[446px] md:underlay rounded-[30px] mt-8" alt="office-pic-1"/>
            <img src="/img/about_res/2.jfif" className="self-center max-w-[336px] lg:max-w-[446px] md:underlay rounded-[30px] mt-4 md:mt-16" alt="office-pic-2"/>
          </div>
          <div className="flex flex-col self-center">
            <img src="/img/about_res/3.jfif" className="max-w-[336px] lg:max-w-[446px] md:underlay rounded-[30px] mt-4 md:mt-16" alt="office-pic-3"/>
            <img src="/img/about_res/4.jfif" className="max-w-[336px] lg:max-w-[446px] md:underlay rounded-[30px] mt-4 md:mt-16" alt="office-pic-4"/>
          </div>
        </div>

      </LightBlock>

      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-sblack"/>
      </div>

      <DarkBlock>
        <div className="flex flex-col xl:flex-row flex-grow items-center justify-center items-start">
          <div className="max-w-[700px] mt-[-50px] mb-[50px] mr-[25px]">
            <img className="min-w-[50px] lg:ml-[-25px]" src="/img/about_res/quotes.svg" alt="quote"/>

            <div className="mt-1">
              На протяжении многих лет мы были частью крупной международной компании, одного из лидеров на мировом рынке
              Ed-Tech и Publishing, что в значительной степени повлияло на нашу инженерную и корпоративную культуру.
            </div>

            <div className="mt-3">
              Наши инженеры легко находят общий язык с любым заказчиком, а в работе сочетают творческий подход с
              грамотным
              планированием. Высокая инженерная культура, опыт и мировые стандарты качества процессов разработки
              позволяют
              нам браться за самые амбициозные задачи и успешно их решать.
            </div>

            <div className="mt-3">
              Наша команда – это сплоченный коллектив высококлассных инженеров
              с многолетним опытом разработки, внедрения и поддержки передовых технологических решений. Большинство
              наших
              сотрудников – выпускники ведущих российских ВУЗов со специализацией в сфере информационных технологий.
            </div>

            <div className="mt-3">
              Мы никогда не стоим на месте: запускаем новые проекты и активно осваиваем новые компетенции.
            </div>

            <div className="mt-3 mr-[25px] float-right">
              <img className="min-w-[50px] mr-[-25px] rotate-180" src="/img/about_res/quotes.svg" alt="quote"/>
            </div>

          </div>
          <div className="max-w-[416px] flex flex-col items-center align-center">
            <div className="flex flex-col justify-center">
              <LeaderSlider/>
              <div className="flex pt-4 flex-col items-center align-center text-center">
                <div className="text-saccent font-header-h3 ">Генеральный директор</div>
                <div className="font-header-h3">Роман Паршин</div>
              </div>
            </div>
          </div>
        </div>
      </DarkBlock>

      <Separator className="rounded-t-3xl bg-sgray" />

      <LightBlock className="pt-8 md:pt-[6.5rem]">
        <div>
          <h2 className="w-full text-center pb-[3.125rem] md:pb-20">Результаты работы</h2>

          <div
            className="flex flex-col lg:flex-row gap-5 lg:gap-6 xl:gap-16 2xl:gap-20 
            flex-grow align-center justify-center items-center text-center">
            <Result header="20+ ЛЕТ" text="успешной работы"/>
            <Result header="120+" text="завершенных проектов"/>
            <Result header="1M+" text="пользователей"/>
          </div>

          <div className="flex flex-col align-center justify-center items-center">
            <div className="mt-3 mt-[90px] max-w-[1000px]">
              Платформы, системы и приложения, созданные нашей командой, известны подавляющему большинству
              исследователей,
              когда-либо публиковавших результаты своих работ в ведущих международных издательствах.
            </div>

            <div className="mt-3 max-w-[1000px]">
              Более 800 000 новых студентов высших и средних учебных заведений ежегодно используют разработанные нами
              системы электронного обучения для получения знаний и сдачи экзаменов по различным дисциплинам.
            </div>
          </div>

        </div>
      </LightBlock>

      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-saccent" />
      </div>

      <LightBlock className="bg-saccent">
        <div className="text-center">
          <h2>Офис</h2>
        </div>

        <div>
          <div className="flex flex-col md:flex-row gap-20 mt-[90px] justify-center items-center md:items-start">
            <div className="md:max-w-[270px] text-center md:text-left">
              <div className="mb-4">
                Наш офис расположен по адресу:
              </div>
              <div className="mb-4">
                Московская обл., г. Королёв, ул. Дзержинского, д. 29, офис 5.
              </div>
              <div className="mb-4">
                <a className="hover:underline" href="tel:+74987190917">+7 (498) 719-0-917</a>
              </div>
              <div>
                <a className="hover:underline" href="mailto:info@sprinterra.ru">info@sprinterra.ru</a>
              </div>
            </div>

            <img src="/img/office0.png" className="self-center w-[336px] md:w-[456px] rounded-[32px]" alt="office-main"/>
          </div>


        </div>
      </LightBlock>
    </div>
  );
}

export default AboutCompany;
