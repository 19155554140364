import { Link } from "react-router-dom";

function MobileMenu({ hideMenu, menuVisible, activeSection, onClick, menu, showDiscussForm }) {

  return <div className={`${menuVisible ? "visible" : ""} font-nunito-r-13 lg:hidden mobile-menu mt-0 bg-sblack text-sgray`}>

    <ul className="">
      {menu.map(({ title, route }) => (<li className={activeSection === route ? "active" : ""}><Link
        onClick={() => { onClick(route); hideMenu(); }}
        className="block text-center hover:bg-green-500 transition duration-300 p-8"
      >
        {title}
      </Link></li>
      ))}
      <li className="mt-10 flex items-center justify-center">
        <Link
          to="#"
          className="text-center hover:bg-green-500 rounded-[2rem] border-[1px] border-1 border-saccent h-[40px] w-[170px]
            transition duration-300 p-[12px]"
          onClick={showDiscussForm}
        >
          НАПИСАТЬ НАМ
        </Link></li>
    </ul>
  </div>
}

export default MobileMenu
